import { SEND_SMS_ACTION, RESET_SMS_RESPONSE_ACTION } from '../../constants/actions'

const initalState = {
    response: {}
}

const smsReducer = (state = initalState, action) => {

    switch (action.type) {

        case SEND_SMS_ACTION:

            return {
                ...state,
                response: action.response
            }

        case RESET_SMS_RESPONSE_ACTION:

            return {
                ...state,
                response: {}
            }

        default:

            return state
    }
}

export default smsReducer
