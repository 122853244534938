export const AUTH_LOGIN_ACTION = 'LOGIN'
export const AUTH_LOGOUT_ACTION = 'LOGOUT'

export const USER_PROFILE_UPDATE_ACTION = 'PROFILE_UPDATE'

export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM'

export const CUSTOMER_GET_LIST_ACTION = 'CUSTOMER_GET_LIST'
export const CUSTOMER_SAVE_ACTION = 'CUSTOMER_SAVE'
export const CUSTOMER_SHOW_ACTION = 'CUSTOMER_SHOW'
export const CUSTOMER_DELETE_ACTION = 'CUSTOMER_DELETE'
export const CUSTOMER_GET_USER_LIST = 'CUSTOMER_GET_USER_LIST'

export const JOB_SITE_GET_LIST_ACTION = 'JOB_SITE_GET_LIST'
export const JOB_SITE_GET_HIDDEN_LIST_ACTION = 'JOB_SITE_GET_HIDDEN_LIST'
export const JOB_SITE_SAVE_ACTION = 'JOB_SITE_SAVE'
export const JOB_SITE_SHOW_ACTION = 'JOB_SITE_SHOW'
export const JOB_SITE_DELETE_ACTION = 'JOB_SITE_DELETE'
export const JOB_SITE_DELETE_ERROR = 'JOB_SITE_DELETE_ERROR'
export const JOB_SITE_CLEAR_ERROR = 'JOB_SITE_CLEAR_ERROR'

export const FORM_SERVER_ERROR_ACTION = 'FORM_SERVER_ERROR'
export const FORM_RESET_SERVER_ERROR_ACTION = 'FORM_RESET_SERVER_ERROR'

export const UPLOAD_IMAGE_ACTION = 'UPLOAD_IMAGE'
export const RESET_IMAGE_ACTION = 'RESET_IMAGE'
export const UPLOAD_INSURANCE_ACTION = 'UPLOAD_INSURANCE_ACTION'
export const RESET_INSURANCE_ACTION = 'RESET_INSURANCE_ACTION'
export const UPLOAD_QR_ACTION = 'UPLOAD_CASH_APP_QR_ACTION'
export const RESET_QR_ACTION = 'RESET_CASH_APP_QR_ACTION'
export const QR_CODE_LOADING_START = 'QR_CODE_LOADING_START'

export const SEND_SMS_ACTION = 'SEND_SMS'
export const RESET_SMS_RESPONSE_ACTION = 'RESET_SMS_RESPONSE'

export const GOOGLE_GEOCODER_GET_ADDRESS_ACTION = 'GOOGLE_GEOCODER_GET_ADDRESS'
export const GOOLGE_GEOCODER_RESET_ADDRESS_ACTION = 'GOOGLE_GEOCODER_RESET_ADDRESS'

export const EMPLOYEE_GET_LIST_ACTION = 'EMPLOYEE_GET_LIST'
export const EMPLOYEE_GET_HIDDEN_LIST_ACTION = 'EMPLOYEE_GET_HIDDEN_LIST'
export const EMPLOYEE_GET_LIST_ALL_ACTION = 'EMPLOYEE_GET_LIST_ALL'
export const EMPLOYEE_GET_SUGGESTIONS_ACTION = 'EMPLOYEE_GET_SUGGESTIONS'
export const EMPLOYEE_SAVE_ACTION = 'EMPLOYEE_SAVE'
export const EMPLOYEE_RESEND_ACTION = 'EMPLOYEE_RESEND_ACTION'
export const EMPLOYEE_SHOW_ACTION = 'EMPLOYEE_SHOW'
export const EMPLOYEE_DELETE_ACTION = 'EMPLOYEE_DELETE'
export const EMPLOYEE_DELETE_ERROR = 'EMPLOYEE_DELETE_ERROR'
export const EMPLOYEE_CLEAR_ERROR = 'EMPLOYEE_CLEAR_ERROR'
export const EMPLOYEE_ATTACH_ACTION = 'EMPLOYEE_ATTACH'

export const SUBSCRIPTION_GET_INSURANCE_INDICATOR_PRICE_ACTION = 'SUBSCRIPTION_GET_INSURANCE_INDICATOR_PRICE'
export const SUBSCRIPTION_UPDATE_INSURANCE_INDICATOR_PRICE_ACTION = 'SUBSCRIPTION_UPDATE_INSURANCE_INDICATOR_PRICE'

export const SUBSCRIPTION_SCHEDULER_GET_LIST_ACTION = 'SUBSCRIPTION_SCHEDULER_GET_LIST'
export const SUBSCRIPTION_SCHEDULER_DELETE_ACTION = 'SUBSCRIPTION_SCHEDULER_DELETE'
export const SUBSCRIPTION_SCHEDULER_SHOW_ACTION = 'SUBSCRIPTION_SCHEDULER_SHOW'
export const SUBSCRIPTION_SCHEDULER_SAVE_ACTION = 'SUBSCRIPTION_SCHEDULER_SAVE'

export const SUBSCRIPTION_TAB_CHANGE_ACTION = 'SUBSCRIPTION_TAB_CHANGE'
export const SUBSCRIPTION_DELETION_ERROR_ACTION = 'SUBSCRIPTION_DELETION_ERROR_ACTION'
export const SUBSCRIPTION_DELETION_ERROR_CLEARED_ACTION = 'SUBSCRIPTION_DELETION_ERROR_CLEARED_ACTION'

export const SUBSCRIPTION_GET_LIST_CAR_TAGS_ACTION = 'SUBSCRIPTION_GET_LIST_AR_TAGS_ACTION'
export const SUBSCRIPTION_UPDATE_CAR_TAGS_ACTION = 'SUBSCRIPTION_UPDATE_CAR_TAGS_ACTION'
export const SUBSCRIPTION_SHOW_CAR_TAGS_ACTION = 'SUBSCRIPTION_SHOW_CAR_TAGS_ACTION'
export const SUBSCRIPTION_DELETE_CAR_TAGS_ACTION = 'SUBSCRIPTION_DELETE_CAR_TAGS_ACTION'
export const SUBSCRIPTION_SAVE_CAR_TAGS_ACTION = 'SUBSCRIPTION_CREATE_CAR_TAGS_ACTION'

export const TOGGLE_EDIT_MODE_ACTION = 'TOGGLE_EDIT_MODE'

export const COMPANY_GET_LIST_ACTION = 'COMPANIES_GET_LIST'
export const COMPANY_SHOW_ACTION = 'COMPANY_SHOW'
export const COMPANY_SAVE_ACTION = 'COMPANY_SAVE'
export const COMPANY_DELETE_ACTION = 'COMPANY_DELETE'
export const COMPANY_GET_ACCOUNT_HOLDER_ACTION = 'COMPANY_GET_ACCOUNT_HOLDER_ACTION'
export const COMPANY_UPDATE_ACCOUNT_HOLDER_ACTION = 'COMPANY_UPDATE_ACCOUNT_HOLDER_ACTION'
export const COMPANY_GET_RATING_ACTION = 'COMPANY_GET_RATING_ACTION'


export const COMPANY_GET_ACTION = 'COMPANY_GET_ACTION'
export const COMPANY_GET_RATES_ACTION = 'COMPANY_GET_RATES_ACTION'
export const COMPANY_UPDATE_PARKING_TERMS = 'COMPANY_UPDATE_PARKING_TERMS'

export const PAYMENTS_HISTORY_LIST_ACTION = 'PAYMENTS_HISTORY_LIST_ACTION'
export const PAYMENTS_HISTORY_SAVE_ACTION = 'PAYMENTS_HISTORY_SAVE_ACTION'
export const PAYMENTS_HISTORY_DELETE_ACTION = 'PAYMENTS_HISTORY_DELETE_ACTION'

export const SHIFT_GET_LIST_ACTION = 'SHIFT_GET_LIST_ACTION'

export const EMPLOYEE_PAYMENTS_GET_RATING_ACTION = 'EMPLOYEE_PAYMENTS_GET_RATING_ACTION'

export const SCHEDULE_GET_LIST = 'SCHEDULE_GET_LIST'

export const DAYS_OFF_GET_LIST = 'DAYS_OFF_GET_LIST'
export const DAYS_OFF_SHOW_ACTION = 'DAYS_OFF_SHOW_ACTION'
export const DAYS_OFF_UPDATE_ACTION = 'DAYS_OFF_UPDATE_ACTION'
export const DAYS_OFF_DELETE_ACTION = 'DAYS_OFF_DELETE_ACTION'
export const DAYS_OFF_CREATE_ACTION = 'DAYS_OFF_CREATE_ACTION'

export const AVAILABILITY_GET_LIST_ACTION = 'AVAILABILITY_GET_LIST_ACTION'
export const AVAILABILITY_SAVE_ACTION = 'AVAILABILITY_SAVE_ACTION'

export const TICKET_GET_LIST_ACTION = 'TICKET_GET_LIST_ACTION'
export const TICKET_SHOW_ACTION = 'TICKET_SHOW_ACTION'
export const TICKET_SAVE_ACTION = 'TICKET_SAVE_ACTION'
export const TICKET_DELETE_ACTION = 'TICKET_DELETE_ACTION'
export const TICKET_GET_LOGS = 'TICKET_GET_LOGS'
export const TICKET_GET_ALL_LOGS = 'TICKET_GET_ALL_LOGS'
export const TICKET_GET_NOTES = 'TICKET_GET__NOTES'
export const TICKET_DELETE_NOTE = 'TICKET_DELETE_NOTE'
export const TICKET_NOTES_HANDLE_CONFIRMATION = 'TICKET_NOTES_HANDLE_CONFIRMATION'
export const TICKET_REFUND_ACTION = 'TICKET_REFUND_ACTION'

export const CONTACT_REQUEST_LIST_ACTION = 'CONTACT_REQUEST_LIST_ACTION'
export const CONTACT_REQUEST_DELETE_ACTION = 'CONTACT_REQUEST_DELETE_ACTION'
export const CONTACT_REQUEST_SHOW_ACTION = 'CONTACT_REQUEST_SHOW_ACTION'
export const CONTACT_REQUEST_SAVE_ACTION = 'CONTACT_REQUEST_SAVE_ACTION'
export const CONTACT_REQUEST_DELETE_COMMENT = 'CONTACT_REQUEST_DELETE_COMMENT'
export const CONTACT_REQUEST_CREATE_COMMENT = 'CONTACT_REQUEST_CREATE_COMMENT'
export const CONTACT_REQUEST_UPDATE_COMMENT = 'CONTACT_REQUEST_UPDATE_COMMENT'

export const SCHEDULER_ACTION = 'SCHEDULER_ACTION'
export const SCHEDULER_CREATE_SHIFT = 'SCHEDULER_CREATE_SHIFT'
export const SCHEDULER_SHOW_SHIFT = 'SCHEDULER_SHOW_SHIFT'
export const SCHEDULER_DELETE_SHIFT = 'SCHEDULER_DELETE_SHIFT'
export const SCHEDULER_DELETE_SCHEDULE = 'SCHEDULER_DELETE_SCHEDULE'
export const SCHEDULER_UPDATE_SHIFT = 'SCHEDULER_UPDATE_SHIFT'
export const SCHEDULER_UPDATE_SCHEDULE = 'SCHEDULER_UPDATE_SCHEDULE'
export const SCHEDULER_PUBLISH_ACTION = 'SCHEDULER_PUBLISH_ACTION'
export const SCHEDULER_GET_ALL_LIST_ACTION = 'SCHEDULER_GET_ALL_LIST_ACTION'

export const SHIFT_TEMPLATES_ACTION = 'SHIFT_TEMPLATES_ACTION'
export const SHIFT_TEMPLATE_SHOW_ACTION = 'SHIFT_TEMPLATE_SHOW_ACTION'
export const SHIFT_TEMPLATE_SAVE_ACTION = 'SHIFT_TEMPLATE_SAVE_ACTION'
export const SHIFT_TEMPLATE_DELETE_ACTION = 'SHIFT_TEMPLATE_DELETE_ACTION'
export const SHIFT_TEMPLATE_UPDATE_ACTION = 'SHIFT_TEMPLATE_UPDATE_ACTION'

export const CHAT_MEMBERS_LIST_ACTION = 'CHAT_MEMBERS_LIST_ACTION'

export const DROP_SHIFT_REQUEST_UPDATE_ACTION = 'DROP_SHIFT_REQUEST_UPDATE_ACTION'
export const DROP_SHIFT_REQUEST_RELOAD_ACTION = 'DROP_SHIFT_REQUEST_RELOAD_ACTION'
export const DROP_SHIFT_REQUEST_DECLINE_ALL_ACTION = 'DROP_SHIFT_REQUEST_DECLINE_ALL_ACTION'

export const SUBSCRIPTION_CREATE_ACTION = 'SUBSCRIPTION_CREATE_ACTION'
export const SUBSCRIPTION_CREATE_PAYMENT = 'SUBSCRIPTION_CREATE_PAYMENT'
export const SUBSCRIPTION_GET_ACTION = 'SUBSCRIPTION_GET_ACTION'
export const SUBSCRIPTION_GET_CAR_TAGS_ACTION = 'SUBSCRIPTION_GET_CAR_TAGS_ACTION'
export const SUBSCRIPTION_GET_CURRENT_CAR_TAGS_ACTION = 'SUBSCRIPTION_GET_CURRENT_CAR_TAGS_ACTION'
export const SUBSCRIPTION_CREATE_CAR_TAGS_ACTION = 'SUBSCRIPTION_CREATE_CAR_TAGS_ACTION'
export const SUBSCRIPTION_GET_LIMIT_EMPLOYEE_ACTION = 'SUBSCRIPTION_GET_LIMIT_EMPLOYEE_ACTION'
export const SUBSCRIPTION_GET_STATUS = 'SUBSCRIPTION_GET_STATUS'
export const SUBSCRIPTION_GET_BALANCE_ACTION = 'SUBSCRIPTION_GET_BALANCE_ACTION'
export const SUBSCRIPTION_UPDATE_BALANCE_ACTION = 'SUBSCRIPTION_UPDATE_BALANCE_ACTION'
export const SUBSCRIPTION_UPDATE_FEES = 'SUBSCRIPTION_UPDATE_FEES'

export const PAYMENT_METHOD_GET_ACTION = 'PAYMENT_METHOD_GET_ACTION'
export const PAYMENT_METHOD_CREATE_ACTION = 'PAYMENT_METHOD_CREATE_ACTION'
export const PAYMENT_METHOD_RELOAD_ACTION = 'PAYMENT_METHOD_RELOAD_ACTION'
export const PAYMENT_METHOD_ADD_ACTION = 'PAYMENT_METHOD_ADD_ACTION'
export const PAYMENT_METHOD_DELETE_ACTION = 'PAYMENT_METHOD_DELETE_ACTION'
export const PAYMENT_METHOD_UPDATE_ACTION = 'PAYMENT_METHOD_UPDATE_ACTION'

export const TRANSACTION_GET_LIST_ACTION = 'TRANSACTION_GET_LIST_ACTION'
export const TRANSACTION_UPDATE_PAYMENT_ACTION = 'TRANSACTION_UPDATE_PAYMENT_ACTION'

export const EARNING_HISTORY_GET_LIST_ACTION = 'EARNING_HISTORY_GET_LIST_ACTION'
export const EARNING_HISTORY_EXPORT_ACTION = 'EARNING_HISTORY_EXPORT_ACTION'
export const EARNING_HISTORY_UNSET_EXPORT_ACTION = 'EARNING_HISTORY_UNSET_EXPORT_ACTION'
export const EARNING_HISTORY_GET_TOTALS_ACTION = 'EARNING_HISTORY_GET_TOTALS_ACTION'

export const EMPLOYEES_WORKLOAD_GET_LIST_ACTION = 'EMPLOYEES_WORKLOAD_GET_LIST_ACTION'
export const EMPLOYEES_WORKLOAD_EXPORT_ACTION = 'EMPLOYEES_WORKLOAD_EXPORT_ACTION'
export const EMPLOYEES_WORKLOAD_UNSET_EXPORT_ACTION = 'EMPLOYEES_WORKLOAD_UNSET_EXPORT_ACTION'
export const EMPLOYEES_WORKLOAD_GET_PARKED_ACTION = 'EMPLOYEES_WORKLOAD_GET_PARKED_ACTION'
export const EMPLOYEES_WORKLOAD_GET_RETRIEVAL_ACTION = 'EMPLOYEES_WORKLOAD_GET_RETRIEVAL_ACTION'
export const EMPLOYEES_WORKLOAD_GET_DEPARTURE_ACTION = 'EMPLOYEES_WORKLOAD_GET_DEPARTURE_ACTION'
export const EMPLOYEES_WORKLOAD_GET_REPARKED_ACTION = 'EMPLOYEES_WORKLOAD_GET_REPARKED_ACTION'
export const EMPLOYEES_WORKLOAD_GET_ACCEPTED_ACTION = 'EMPLOYEES_WORKLOAD_GET_ACCEPTED_ACTION'
export const EMPLOYEES_WORKLOAD_UNSET_PARKED_ACTION = 'EMPLOYEES_WORKLOAD_UNSET_PARKED_ACTION'
export const EMPLOYEES_WORKLOAD_UNSET_RETRIEVAL_ACTION = 'EMPLOYEES_WORKLOAD_UNSET_RETRIEVAL_ACTION'
export const EMPLOYEES_WORKLOAD_UNSET_DEPARTURE_ACTION = 'EMPLOYEES_WORKLOAD_UNSET_DEPARTURE_ACTION'
export const EMPLOYEES_WORKLOAD_UNSET_REPARKED_ACTION = 'EMPLOYEES_WORKLOAD_UNSET_REPARKED_ACTION'
export const EMPLOYEES_WORKLOAD_UNSET_ACCEPTED_ACTION = 'EMPLOYEES_WORKLOAD_UNSET_ACCEPTED_ACTION'

export const EMPLOYEES_TICKETS_PAYMENT_GET_LIST_ACTION = 'EMPLOYEES_TICKETS_PAYMENT_GET_LIST_ACTION'
export const EMPLOYEES_TICKETS_PAYMENT_EXPORT_ACTION = 'EMPLOYEES_TICKETS_PAYMENT_EXPORT_ACTION'
export const EMPLOYEES_TICKETS_PAYMENT_UNSET_EXPORT_ACTION = 'EMPLOYEES_TICKETS_PAYMENT_UNSET_EXPORT_ACTION'

export const ADMIN_EARNING_HISTORY_GET_LIST_ACTION = 'ADMIN_EARNING_HISTORY_GET_LIST_ACTION'
export const ADMIN_EARNING_HISTORY_EXPORT_ACTION = 'ADMIN_EARNING_HISTORY_EXPORT_ACTION'
export const ADMIN_EARNING_HISTORY_UNSET_EXPORT_ACTION = 'ADMIN_EARNING_HISTORY_UNSET_EXPORT_ACTION'
export const SUBSCRIPTION_PAYMENTS_GET_LIST_ACTION = 'SUBSCRIPTION_PAYMENTS_GET_LIST_ACTION'
export const SUBSCRIPTION_PAYMENTS_EXPORT_ACTION = 'SUBSCRIPTION_PAYMENTS_EXPORT_ACTION'
export const SUBSCRIPTION_PAYMENTS_UNSET_EXPORT_ACTION = 'SUBSCRIPTION_PAYMENTS_UNSET_EXPORT_ACTION'

export const INSURANCE_GET_LIST_ACTION = 'INSURANCE_GET_LIST_ACTION'
export const INSURANCE_GET_PROCESSED_LIST_ACTION = 'INSURANCE_GET_PROCESSED_LIST_ACTION'
export const INSURANCE_UPDATE_ACTION = 'INSURANCE_UPDATE_ACTION'

export const DASHBOARD_GET_ACTION = 'DASHBOARD_GET_ACTION'

export const PAYOUT_GET_ACTION = 'PAYOUT_GET_ACTION'
export const PAYOUT_CREATE_ACTION = 'PAYOUT_CREATE_ACTION'
export const PAYOUT_GET_CARDS_ACTION = 'PAYOUT_GET_CARDS_ACTION'
export const PAYOUT_SET_CARD_ACTION = 'PAYOUT_SET_CARD_ACTION'
export const PAYOUT_DELETE_CARD_ACTION = 'PAYOUT_DELETE_CARD_ACTION'
export const PAYOUT_SET_DEFAULT_CARD_ACTION = 'PAYOUT_SET_DEFAULT_CARD_ACTION'

export const EMPLOYEE_ACTIVITY_GET_LIST_ACTION = 'EMPLOYEE_ACTIVITY_GET_LIST_ACTION'

export const GET_ADMIN_BALANCE_SERVICE = 'GET_ADMIN_BALANCE_SERVICE'
