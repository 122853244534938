import {
    INSURANCE_GET_LIST_ACTION,
    INSURANCE_GET_PROCESSED_LIST_ACTION,
    INSURANCE_UPDATE_ACTION
} from "../../constants/actions"

const initialState = {
    list: {
        data: []
    },
    processedList: [],
    reload: false
}

const insuranceReducer = (state = initialState, action) => {

    switch (action.type) {

        case INSURANCE_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list,
                reload: false
            }

        case INSURANCE_UPDATE_ACTION:
            return {
                ...state,
                reload: true
            }

        case INSURANCE_GET_PROCESSED_LIST_ACTION:
            return {
                ...state,
                processedList: action.list
            }

        default: return state
    }
}

export default insuranceReducer