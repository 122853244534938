import { GOOGLE_GEOCODER_GET_ADDRESS_ACTION, GOOLGE_GEOCODER_RESET_ADDRESS_ACTION } from '../../constants/actions'

const initialState = {
    geoCodeAddress: null
}

/**
 * Google reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */
const googleReducer = (state = initialState, action) => {

    switch (action.type) {

        case GOOGLE_GEOCODER_GET_ADDRESS_ACTION:

            return {
                ...state,
                geoCodeAddress: action.address
            }

        case GOOLGE_GEOCODER_RESET_ADDRESS_ACTION:

            return {
                ...state,
                geoCodeAddress: null
            }

        default:

            return state
    }
}

export default googleReducer
