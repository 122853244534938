import axios from 'axios'
import Cookies from 'js-cookie'

import AbstractService from '../../abstractService'

/**
 * @class JwtService
 */
export default class JwtService extends AbstractService {

    /**
     * User login
     *
     * @param args
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    login(...args) {

        return axios.post(this.getUrl(this.config.loginEndpoint), ...args)
    }

    /**
     * User registration
     *
     * @param args
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    register(...args) {

        return axios.post(this.getUrl(this.config.registerEndpoint), ...args)
    }

    /**
     * Forgot password request
     *
     * @param args
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    requestPassword(...args) {

        return axios.post(this.getUrl(this.config.requestPasswordEndpoint), ...args)
    }

    /**
     * Reset password request
     *
     * @param args
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    resetPassword(...args) {

        return axios.put(this.getUrl(this.config.setPasswordEndpoint), ...args)
    }

    /**
     * Logout request
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    logout() {

        return axios.delete(this.getUrl(this.config.logoutEndpoint))
    }

    /**
     * Set access token
     *
     * @param {string} value
     * @param {boolean} remember
     */
    setToken(value, remember = false) {

        const options = {}

        if (remember) {

            options['expires'] = 7
        }

        Cookies.set(this.config.storageTokenKeyName, value, {
            SameSite: 'strict',
            ...options
        })
    }

    /**
     * Remove access token
     */
    removeToken() {

        Cookies.remove(this.config.storageTokenKeyName)
    }

    /**
     * Get user data
     *
     * @returns {object}
     */
    getUserData() {

        const userData = Cookies.get(this.config.storageUserDataKeyName)

        return userData ? JSON.parse(userData) : null
    }

    /**
     * Set user data
     *
     * @param {object} userData
     * @param {boolean} remember
     */
    setUserData(userData, remember = false) {
        const options = {}

        if (remember) {

            options['expires'] = 7
        }

        Cookies.set(this.config.storageUserDataKeyName, JSON.stringify(userData), {
            SameSite: 'strict',
            ...options
        })
    }

    /**
     * Get company data
     * @returns {Record<string, any>}
     */
    getSubscriptionData() {
        const data = Cookies.get(this.config.storageSubscriptionKeyData)

        return data ? JSON.parse(data) : null
    }

    /**
     * Set company data
     * @param {Record<string, any>} data 
     */
    setSubscriptionData(data) {

        Cookies.set(this.config.storageSubscriptionKeyData, JSON.stringify(data), {
            SameSite: 'strict',
            expires: 7
        })
    }

    /**
     * Remove company data
     */
    removeSubscriptionData() {
        Cookies.remove(this.config.storageSubscriptionKeyData)
    }

    /**
     * Get company balance data
     * @returns {Record<string, any>}
     */
    getBalanceData() {
        const data = Cookies.get(this.config.storageBalanceKeyData)

        return data ? JSON.parse(data) : null
    }

    /**
     * Set company balance data
     * @param {Record<string, any>} data
     */
    setBalanceData(data) {

        Cookies.set(this.config.storageBalanceKeyData, JSON.stringify(data), {
            SameSite: 'strict',
            expires: 7
        })
    }

    /**
     * Remove company balance data
     */
    removeBalanceData() {
        Cookies.remove(this.config.storageBalanceKeyData)
    }

    /**
     * Get company payout data
     * @returns {Record<string, any>}
     */
    getPayoutData() {
        const data = Cookies.get(this.config.storagePayoutData)

        return data ? JSON.parse(data) : null
    }

    /**
     * Set company payout data
     * @param {Record<string, any>} data
     */
    setPayoutData(data) {

        Cookies.set(this.config.storagePayoutData, JSON.stringify(data), {
            SameSite: 'strict',
            expires: 7
        })
    }

    /**
     * Remove company payout data
     */
    removePayoutData() {
        Cookies.remove(this.config.storagePayoutData)
    }


    /**
     * Remove user data
     * Remove user data
     */
    removeUserData() {

        Cookies.remove(this.config.storageUserDataKeyName)
    }
}
