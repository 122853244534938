import axios from 'axios'
import Cookies from 'js-cookie'
import UrlPattern from 'url-pattern'
import history from 'history/browser'

import { store } from '../redux/storeConfig/store'
import { handleLogout } from '../redux/actions/auth'

//TODO: Add server errors handler here

/**
 * @class AbstractService
 */
export default class AbstractService {

    /**
     * Default config
     *
     * @type {object}
     */
    config = {
        // ** This will be prefixed in authorization header with token
        // ? e.g. Authorization: Bearer <token>
        tokenType: 'Bearer',

        // ** Value of this property will be used as key to store JWT token in storage
        storageTokenKeyName: 'accessToken'
    }

    /**
     * Error messages
     *
     * @type {Object}
     */
    errors = {}

    /**
     * AbstractService constructor
     *
     * @param {object} config
     */
    constructor(config = {}) {

        this.config = { ...this.config, ...config }

        // ** Request Interceptor
        axios.interceptors.request.use(
            config => {
                // ** Get token from localStorage
                const accessToken = this.getToken()

                // ** If token is present add it to request's Authorization Header
                if (accessToken) {
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.config.tokenType} ${accessToken}`
                }

                return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        axios.interceptors.response.use(
            response => response,
            error => {

                return Promise.reject(error)
            }
        )
    }

    /**
     * Get access token
     *
     * @returns {string}
     */
    getToken() {

        return Cookies.get(this.config.storageTokenKeyName)
    }

    /**
     * Get service URL
     *
     * @param {string} pattern
     * @param {object} params
     *
     * @returns {string}
     */
    getUrl(pattern, params = {}) {

        return process.env.REACT_APP_API_ENDPOINT + (
            new UrlPattern(pattern)
        ).stringify(params)
    }

    /**
     * Handle errors from server
     *
     * @param {object} error
     */
    errorsHandler(error) {

        const { response } = error

        this.errors = {}

        if (response) {

            switch (response.status) {

                case 422:

                    const { data } = response

                    if (data.errors !== undefined) {

                        Object.keys(data.errors).forEach(field => {

                            const [error] = data.errors[field]

                            this.errors[field] = {
                                type: 'server',
                                message: error
                            }
                        })

                    } else {

                        this.errors['common'] = data.message
                    }

                    break

                case 404:

                    this.errors['common'] = 'Record not found'

                    break

                case 401:

                    store.dispatch(handleLogout())

                    history.push('/login')

                    break

                default:

                    this.errors['common'] = 'Unknown server error'

                    break
            }
        }
    }

    /**
     * Get error messages
     *
     * @returns {Object}
     */
    getErrors() {

        return this.errors
    }
}
