import {
    CONTACT_REQUEST_CREATE_COMMENT,
    CONTACT_REQUEST_DELETE_ACTION, CONTACT_REQUEST_DELETE_COMMENT,
    CONTACT_REQUEST_LIST_ACTION, CONTACT_REQUEST_SAVE_ACTION,
    CONTACT_REQUEST_SHOW_ACTION, CONTACT_REQUEST_UPDATE_COMMENT
} from "../../constants/actions"

const initialState = {
    list: {
        page: 1,
        data: [],
        total: 0
    },
    current: {},
    reload: false
}

const contactRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_REQUEST_LIST_ACTION :
            return {
                ...state,
                list: action.list,
                reload: false
            }
        case CONTACT_REQUEST_DELETE_ACTION:
            return {
                ...state,
                current: {},
                reload: true
            }
        case CONTACT_REQUEST_SHOW_ACTION: {
            return {
                ...state,
                current: action.contactRequest,
                reload: false
            }
        }
        case CONTACT_REQUEST_SAVE_ACTION: {
            return {
                ...state,
                reload: true
            }
        }
        case CONTACT_REQUEST_DELETE_COMMENT: {
            return {
                ...state,
                reload: true
            }
        }
        case CONTACT_REQUEST_CREATE_COMMENT: {
            return {
                ...state,
                reload: true
            }
        }
        case CONTACT_REQUEST_UPDATE_COMMENT: {
            return {
                ...state,
                reload: true
            }
        }
        default: return state
    }
}

export default contactRequestReducer