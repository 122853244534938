import { SUBSCRIPTION_DELETION_ERROR_ACTION,
         SUBSCRIPTION_DELETION_ERROR_CLEARED_ACTION } from '../../constants/actions'

const initialState = {
    isError: false,
    message: ''
}

const tabsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SUBSCRIPTION_DELETION_ERROR_ACTION:
            return {
                isError: true,
                message: action.errors.id.message
            }
        case SUBSCRIPTION_DELETION_ERROR_CLEARED_ACTION:
           return {
             isError: false,
             message: ''
           }
        default:
            return state    
    }

}

export default tabsReducer