import {
    SUBSCRIPTION_GET_BALANCE_ACTION,
    SUBSCRIPTION_UPDATE_BALANCE_ACTION
} from "../../constants/actions"

const initialState = {
    balance: {},
    reload: false
}

const balanceReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_GET_BALANCE_ACTION: {
            return {
                ...state,
                balance: action.balance,
                reload: false
            }
        }

        case SUBSCRIPTION_UPDATE_BALANCE_ACTION: {
            return {
                ...state,
                reload: true
            }
        }

        default: return state
    }
}

export default balanceReducer