import {
    SHIFT_TEMPLATE_DELETE_ACTION,
    SHIFT_TEMPLATE_SAVE_ACTION, SHIFT_TEMPLATE_SHOW_ACTION,
    SHIFT_TEMPLATE_UPDATE_ACTION,
    SHIFT_TEMPLATES_ACTION
} from "../../constants/actions"

const initialState = {
    list: [],
    reload: false,
    current: {}
}

const shiftTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHIFT_TEMPLATES_ACTION:
            return {
                ...state,
                list: action.list,
                reload: false
            }
        case SHIFT_TEMPLATE_SAVE_ACTION:
            return {
                ...state,
                reload: true
            }
        case SHIFT_TEMPLATE_DELETE_ACTION:
            return {
                ...state,
                reload: true
            }
        case SHIFT_TEMPLATE_UPDATE_ACTION:
            return {
                ...state,
                reload: true
            }
        case SHIFT_TEMPLATE_SHOW_ACTION:
            return {
                ...state,
                current: action.current
            }
        default:
            return state
    }
}

export default shiftTemplateReducer