import {
    ADMIN_EARNING_HISTORY_EXPORT_ACTION,
    ADMIN_EARNING_HISTORY_GET_LIST_ACTION, ADMIN_EARNING_HISTORY_UNSET_EXPORT_ACTION,
    EARNING_HISTORY_EXPORT_ACTION,
    EARNING_HISTORY_GET_LIST_ACTION
} from "../../constants/actions"

const initialState = {
    list: {},
    file: null,
    reload: false
}

const earningHistoryReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADMIN_EARNING_HISTORY_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list
            }

        case ADMIN_EARNING_HISTORY_EXPORT_ACTION:
            return {
                ...state,
                file: action.doc
            }

        case ADMIN_EARNING_HISTORY_UNSET_EXPORT_ACTION:
            return {
                ...state,
                file: null
            }

        default:
            return state

    }
}

export default earningHistoryReducer