import {
    SUBSCRIPTION_SCHEDULER_DELETE_ACTION,
    SUBSCRIPTION_SCHEDULER_GET_LIST_ACTION,
    SUBSCRIPTION_SCHEDULER_SHOW_ACTION,
    SUBSCRIPTION_SCHEDULER_SAVE_ACTION
} from '../../constants/actions'

const initialState = {
    list: {
        data: []
    },
    reload: false,
    current: {},
    return: false
}

/**
* Vehicles scan reducer
*
* @param {object} state
* @param {object} action
*
* @returns {object}
*/
const schedulerReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_SCHEDULER_GET_LIST_ACTION:

            return {
                ...state,
                list: action.list,
                current: {},
                reload: false,
                return: false
            }
        
        case SUBSCRIPTION_SCHEDULER_DELETE_ACTION:

            return {
                ...state,
                reload: true,
                current: {}
            }

        case SUBSCRIPTION_SCHEDULER_SHOW_ACTION:

            return {
                ...state,
                current: action.payload
            }

        case SUBSCRIPTION_SCHEDULER_SAVE_ACTION:

            return {
                ...state,
                return: true,
                current: {}
            }

        default:

            return state
    }
}

export default schedulerReducer
