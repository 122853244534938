import {EMPLOYEE_ACTIVITY_GET_LIST_ACTION} from "../../constants/actions"

const initialState = {
    list: [],
    reload: false
}

const activityReducer = (state = initialState, action) => {

    switch (action.type) {

        case EMPLOYEE_ACTIVITY_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list
            }

        default: return state
    }
}

export default activityReducer