import {
    SUBSCRIPTION_GET_INSURANCE_INDICATOR_PRICE_ACTION,
    SUBSCRIPTION_UPDATE_INSURANCE_INDICATOR_PRICE_ACTION,
    TOGGLE_EDIT_MODE_ACTION
} from '../../constants/actions'

const initialState = {
    value: {},
    editMode: false
}

/**
 * Insurance indicator reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */
const insuranceIndicatorPriceReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_GET_INSURANCE_INDICATOR_PRICE_ACTION:

            return {
                ...state,
                value: action.payload
            }

        case SUBSCRIPTION_UPDATE_INSURANCE_INDICATOR_PRICE_ACTION:

            return {
                ...state,
                value: action.payload,
                editMode: false
            }

        case TOGGLE_EDIT_MODE_ACTION:

            return {
                ...state,
                editMode: action.payload
            }

        default:

            return state
    }
}

export default insuranceIndicatorPriceReducer
