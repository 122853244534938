import {
    PAYOUT_CREATE_ACTION, PAYOUT_DELETE_CARD_ACTION,
    PAYOUT_GET_ACTION,
    PAYOUT_GET_CARDS_ACTION,
    PAYOUT_SET_CARD_ACTION, PAYOUT_SET_DEFAULT_CARD_ACTION
} from "../../constants/actions"

const initialState = {
    payout: {},
    cardList: {},
    reloadCards: false,
    isNew: false,
    success: false
}

const payoutReducer = (state = initialState, action) => {

    switch (action.type) {

        case PAYOUT_GET_ACTION:
            return {
                ...state,
                payout: action.payout,
                success: false
            }

        case PAYOUT_CREATE_ACTION:
            return {
                ...state,
                success: true
            }

        case PAYOUT_GET_CARDS_ACTION:
            return {
                ...state,
                cardList: action.list,
                reloadCards: false
            }

        case PAYOUT_SET_CARD_ACTION:
            return {
                ...state,
                reloadCards: true
            }

        case PAYOUT_DELETE_CARD_ACTION:
            return {
                ...state,
                reloadCards: true
            }

        case PAYOUT_SET_DEFAULT_CARD_ACTION:
            return {
                ...state,
                reloadCards: true
            }

        default: return state
    }
}

export default payoutReducer

