import {
    EMPLOYEE_GET_LIST_ACTION,
    EMPLOYEE_GET_LIST_ALL_ACTION,
    EMPLOYEE_GET_SUGGESTIONS_ACTION,
    EMPLOYEE_SAVE_ACTION,
    EMPLOYEE_SHOW_ACTION,
    EMPLOYEE_DELETE_ACTION,
    EMPLOYEE_ATTACH_ACTION, EMPLOYEE_CLEAR_ERROR, EMPLOYEE_DELETE_ERROR,
    EMPLOYEE_GET_HIDDEN_LIST_ACTION,
    EMPLOYEE_RESEND_ACTION
} from '../../constants/actions'

const initialState = {
    list: {
        page: 1,
        data: [],
        total: 0
    },
    hiddenList: {
        page: 1,
        data: [],
        total: 0
    },
    suggestions: [],
    current: {},
    reload: false,
    return: false,
    error: false,
    resend_invite: false,
}

/**
 * Employee reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */

const employeeReducer = (state = initialState, action) => {

    switch (action.type) {

        case EMPLOYEE_GET_LIST_ACTION:

            return {
                ...state,
                list: action.list,
                current: {},
                reload: false,
                return: false
            }
        
        case EMPLOYEE_GET_HIDDEN_LIST_ACTION:

            return {
                ...state,
                hiddenList: action.hiddenList,
                current: {},
                reload: false,
                return: false
            }

        case EMPLOYEE_GET_LIST_ALL_ACTION:

            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.list
                },
                current: {},
                reload: false,
                return: false
            }

        case EMPLOYEE_RESEND_ACTION:

            return {
                ...state,
                resend_invite: true,
            }

        case EMPLOYEE_GET_SUGGESTIONS_ACTION:

            return {
                ...state,
                suggestions: action.suggestions
            }

        case EMPLOYEE_SHOW_ACTION:

            return {
                ...state,
                current: action.employee
            }

        case EMPLOYEE_SAVE_ACTION:

            return {
                ...state,
                return: true,
                current: {}
            }

        case EMPLOYEE_DELETE_ACTION:

            return {
                ...state,
                reload: true,
                current: {}
            }

        case EMPLOYEE_DELETE_ERROR:

            return {
                ...state,
                error: true
            }

        case EMPLOYEE_CLEAR_ERROR:

            return {
                ...state,
                error: false
            }

        case EMPLOYEE_ATTACH_ACTION:

            return {
                ...state,
                return: true,
                current: {}
            }

        default:

            return state
    }
}

export default employeeReducer
