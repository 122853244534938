import {
    SUBSCRIPTION_PAYMENTS_EXPORT_ACTION,
    SUBSCRIPTION_PAYMENTS_GET_LIST_ACTION,
    SUBSCRIPTION_PAYMENTS_UNSET_EXPORT_ACTION
} from "../../constants/actions"

const initialState = {
    list: [],
    file: null,
    reload: false
}

const paymentReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_PAYMENTS_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list
            }

        case SUBSCRIPTION_PAYMENTS_EXPORT_ACTION:
            return {
                ...state,
                file: action.doc
            }

        case SUBSCRIPTION_PAYMENTS_UNSET_EXPORT_ACTION:
            return {
                ...state,
                file: null
            }

        default: return state
    }
}

export default paymentReducer