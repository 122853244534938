import {
    SCHEDULER_CREATE_SHIFT,
    SCHEDULER_ACTION,
    SCHEDULER_SHOW_SHIFT,
    SCHEDULER_DELETE_SHIFT,
    SCHEDULER_DELETE_SCHEDULE,
    SCHEDULER_UPDATE_SHIFT, SCHEDULER_UPDATE_SCHEDULE, SCHEDULER_PUBLISH_ACTION, SCHEDULER_GET_ALL_LIST_ACTION
} from '../../constants/actions'

const initialState = {
    list: [],
    fullList: [],
    current: [],
    reload: true
}

const schedulerReducer = (
    state = initialState,
    action
) => {

    switch (action.type) {

        case SCHEDULER_ACTION:
            return {
                ...state,
                list: action.list,
                reload: false
            }
        case SCHEDULER_CREATE_SHIFT:
            return {
                ...state,
                reload: true
            }
        case SCHEDULER_SHOW_SHIFT:
            return {
                ...state,
                current: action.current,
                reload: false
            }
        case SCHEDULER_DELETE_SHIFT:
            return {
                ...state,
                reload: true
            }
        case SCHEDULER_DELETE_SCHEDULE:
            return {
                ...state,
                reload: true
            }
        case SCHEDULER_UPDATE_SHIFT:
            return {
                ...state,
                reload: true
            }
        case SCHEDULER_UPDATE_SCHEDULE:
            return {
                ...state,
                reload: true
            }
        case SCHEDULER_PUBLISH_ACTION: {
            return {
                ...state,
                reload: true
            }
        }
        case SCHEDULER_GET_ALL_LIST_ACTION: {
            return {
                ...state,
                fullList: action.list,
                reload: false
            }
        }
        default:
            return state

    }
}

export default schedulerReducer
