import {
    SHIFT_GET_LIST_ACTION
} from './../../constants/actions'

const initialState = {
    list: {
        data: []
    },
    current: {}
}

const shiftReducer = (state = initialState, action) => {

    switch (action.type) {

        case SHIFT_GET_LIST_ACTION:
            return {
                ...state,
                list: {
                    data: action.list
                }
            }
        default:
            return state

    }
}

export default shiftReducer