import { AUTH_LOGIN_ACTION, AUTH_LOGOUT_ACTION, USER_PROFILE_UPDATE_ACTION } from '../../constants/actions'

// **  Initial State
const initialState = {
  userData: {},
  token: null
}

const authReducer = (state = initialState, action) => {

    switch (action.type) {

        case AUTH_LOGIN_ACTION:

            return {
                ...state,
                userData: action.userData,
                token: action.token
            }

        case USER_PROFILE_UPDATE_ACTION:

            return {
                ...state,
                userData: action.userData
            }

        case AUTH_LOGOUT_ACTION:

            const obj = { ...action }

            delete obj.type

            return { ...state, userData: {}, ...obj }

        default:

            return state
    }
}

export default authReducer
