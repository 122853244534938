import {
    SUBSCRIPTION_CREATE_ACTION,
    SUBSCRIPTION_CREATE_PAYMENT,
    SUBSCRIPTION_GET_ACTION,
    SUBSCRIPTION_GET_STATUS,
    SUBSCRIPTION_UPDATE_FEES
} from "../../constants/actions"

const initialState = {
    subscription: {},
    payment: {},
    status: null,
    reload: false
}

const subscriptionDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_CREATE_PAYMENT:
            return {
                ...state,
                payment: action.payment
            }
        case SUBSCRIPTION_GET_ACTION:
            return {
                ...state,
                subscription: action.subscription,
                reload: false
            }
        case SUBSCRIPTION_CREATE_ACTION: {
            return {
                ...state,
                reload: true
            }
        }
        case SUBSCRIPTION_GET_STATUS: 
            return {
                ...state,
                status: action.status
            }
        case SUBSCRIPTION_UPDATE_FEES: {
            return {
                ...state,
                reload: true
            }
        }
        default:
            return state
    }
}

export default subscriptionDetailsReducer