import {
    CHAT_MEMBERS_LIST_ACTION
} from './../../constants/actions'

const initialState = {
    users: []
}

const chatReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHAT_MEMBERS_LIST_ACTION:
            return {
                ...state,
                users: action.list?.users || []
            }
        default: 
            return state
    }
}

export default chatReducer