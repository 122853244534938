import {
    SUBSCRIPTION_DELETE_CAR_TAGS_ACTION,
    SUBSCRIPTION_GET_LIST_CAR_TAGS_ACTION,
    SUBSCRIPTION_SAVE_CAR_TAGS_ACTION,
    SUBSCRIPTION_SHOW_CAR_TAGS_ACTION, SUBSCRIPTION_UPDATE_CAR_TAGS_ACTION
} from "../../constants/actions"

const initialState = {
    list: {},
    current: {},
    reload: false
}

const carTagsAdminReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_GET_LIST_CAR_TAGS_ACTION:
            return {
                ...state,
                list: action.list,
                reload: false
            }

        case SUBSCRIPTION_SAVE_CAR_TAGS_ACTION: {
            return {
                ...state,
                reload: true
            }
        }

        case SUBSCRIPTION_SHOW_CAR_TAGS_ACTION: {
            return {
                ...state,
                current: action.payload
            }
        }

        case SUBSCRIPTION_UPDATE_CAR_TAGS_ACTION: {
            return {
                ...state,
                reload: true
            }
        }

        case SUBSCRIPTION_DELETE_CAR_TAGS_ACTION: {
            return {
                ...state,
                reload: true
            }
        }

        default: return state
    }
}

export default carTagsAdminReducer