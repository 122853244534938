import {DASHBOARD_GET_ACTION} from "../../constants/actions"

const initialState = {
    list: {}
}

const dashboardReducer = (state = initialState, action) => {

    switch (action.type) {

        case DASHBOARD_GET_ACTION:
            return {
                ...state,
                list: action.list
            }
        default: return state
    }
}

export default dashboardReducer