import {
    EMPLOYEES_WORKLOAD_EXPORT_ACTION,
    EMPLOYEES_WORKLOAD_GET_LIST_ACTION,
    EMPLOYEES_WORKLOAD_UNSET_EXPORT_ACTION,
    EMPLOYEES_WORKLOAD_GET_ACCEPTED_ACTION,
    EMPLOYEES_WORKLOAD_GET_DEPARTURE_ACTION,
    EMPLOYEES_WORKLOAD_GET_PARKED_ACTION,
    EMPLOYEES_WORKLOAD_GET_REPARKED_ACTION,
    EMPLOYEES_WORKLOAD_GET_RETRIEVAL_ACTION,
    EMPLOYEES_WORKLOAD_UNSET_ACCEPTED_ACTION,
    EMPLOYEES_WORKLOAD_UNSET_DEPARTURE_ACTION,
    EMPLOYEES_WORKLOAD_UNSET_PARKED_ACTION,
    EMPLOYEES_WORKLOAD_UNSET_REPARKED_ACTION,
    EMPLOYEES_WORKLOAD_UNSET_RETRIEVAL_ACTION
} from "../../constants/actions"

const initialState = {
    list: [],
    reload: true,
    file: null,
    parked: null,
    retrieval: null,
    departure: null,
    reparked: null,
    accepted: null
}

const workloadReducer = (state = initialState, action) => {

    switch (action.type) {

        case EMPLOYEES_WORKLOAD_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list
            }

        case EMPLOYEES_WORKLOAD_EXPORT_ACTION:
            return {
                ...state,
                file: action.doc
            }

        case EMPLOYEES_WORKLOAD_UNSET_EXPORT_ACTION: {
            return {
                ...state,
                file: null
            }
        }

        case EMPLOYEES_WORKLOAD_GET_PARKED_ACTION:
            return {
                ...state,
                parked: action.list
            }

        case EMPLOYEES_WORKLOAD_GET_RETRIEVAL_ACTION:
            return {
                ...state,
                retrieval: action.list
            }

        case EMPLOYEES_WORKLOAD_GET_DEPARTURE_ACTION:
            return {
                ...state,
                departure: action.list
            }

        case EMPLOYEES_WORKLOAD_GET_REPARKED_ACTION:
            return {
                ...state,
                reparked: action.list
            }

        case EMPLOYEES_WORKLOAD_GET_ACCEPTED_ACTION:
            return {
                ...state,
                accepted: action.list
            }

        case EMPLOYEES_WORKLOAD_UNSET_PARKED_ACTION:
            return {
                ...state,
                parked: null
            }

        case EMPLOYEES_WORKLOAD_UNSET_RETRIEVAL_ACTION:
            return {
                ...state,
                retrieval: null
            }

        case EMPLOYEES_WORKLOAD_UNSET_DEPARTURE_ACTION:
            return {
                ...state,
                departure: null
            }

        case EMPLOYEES_WORKLOAD_UNSET_REPARKED_ACTION:
            return {
                ...state,
                reparked: null
            }

        case EMPLOYEES_WORKLOAD_UNSET_ACCEPTED_ACTION:
            return {
                ...state,
                accepted: null
            }
        default: return state
    }
}

export default workloadReducer