import {
    CUSTOMER_GET_LIST_ACTION,
    CUSTOMER_SAVE_ACTION,
    CUSTOMER_SHOW_ACTION,
    CUSTOMER_DELETE_ACTION, CUSTOMER_GET_USER_LIST
} from '../../constants/actions'

const initialState = {
    list: {
        page: 1,
        data: [],
        total: 0
    },
    userList: [],
    current: {},
    reload: false,
    return: false
}

/**
 * Customer reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */
const customerReducer = (state = initialState, action) => {

    switch (action.type) {

        case CUSTOMER_GET_LIST_ACTION:

            return {
                ...state,
                list: action.list,
                current: {},
                reload: false,
                return: false
            }

        case CUSTOMER_SHOW_ACTION:

            return {
                ...state,
                current: action.customer
            }

        case CUSTOMER_SAVE_ACTION:

            return {
                ...state,
                return: true,
                current: {}
            }

        case CUSTOMER_DELETE_ACTION:

            return {
                ...state,
                reload: true,
                current: {}
            }

        case CUSTOMER_GET_USER_LIST: {
            return {
                state,
                userList: action.list
            }
        }

        default:

            return state
    }
}

export default customerReducer
