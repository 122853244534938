import {
    PAYMENT_METHOD_ADD_ACTION,
    PAYMENT_METHOD_CREATE_ACTION, PAYMENT_METHOD_DELETE_ACTION,
    PAYMENT_METHOD_GET_ACTION,
    PAYMENT_METHOD_RELOAD_ACTION, PAYMENT_METHOD_UPDATE_ACTION
} from "../../constants/actions"

const initialState = {
    methods: [],
    setupIntent: {},
    reload: false
}

const paymentMethodReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_METHOD_GET_ACTION:
            return {
                ...state,
                methods : action.methods,
                reload: false
            }
        case PAYMENT_METHOD_CREATE_ACTION:
            return {
                ...state,
                setupIntent: action.payment,
                reload: true
            }
        case PAYMENT_METHOD_RELOAD_ACTION: {
            return {
                ...state,
                reload: true
            }
        }
        case PAYMENT_METHOD_ADD_ACTION: {
            return {
                ...state,
                reload : true
            }
        }
        case PAYMENT_METHOD_UPDATE_ACTION: {
            return {
                ...state,
                reload: true
            }
        }
        case PAYMENT_METHOD_DELETE_ACTION: {
            return {
                ...state,
                reload: true
            }
        }
        default:
            return state
    }
}

export default paymentMethodReducer