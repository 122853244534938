import {combineReducers} from "redux"
import earningHistory from "./earningHistory"
import workload from "./workload"
import ticketsPayment from "./ticketsPayment"

const statisticsReducer = combineReducers({
        earningHistory,
        workload,
        ticketsPayment,
    }
)

export default statisticsReducer