import { combineReducers } from 'redux'

import insuranceIndicatorPrice from './insuranceIndicator'
import scheduler from './scheduler'
import tabs from './tabs'
import errors from './errors'
import subscriptionDetails from "./subscription"
import paymentMethod from "./paymentMethod"
import transaction from "./transaction"
import carTags from "./carTags"
import limit from "./limit"
import balance from "./balance"
import carTagsAdmin from "./carTagsAdmin"
import payout from './payout'

const subscriptionReducer = combineReducers({
    tabs,
    insuranceIndicatorPrice,
    scheduler,
    errors,
    subscriptionDetails,
    paymentMethod,
    transaction,
    carTags,
    limit,
    balance,
    carTagsAdmin,
    payout
})

export default subscriptionReducer
