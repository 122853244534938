import {
    EMPLOYEES_TICKETS_PAYMENT_EXPORT_ACTION,
    EMPLOYEES_TICKETS_PAYMENT_GET_LIST_ACTION,
    EMPLOYEES_TICKETS_PAYMENT_UNSET_EXPORT_ACTION,
} from "../../constants/actions"

const initialState = {
    list: {},
    reload: true,
    file: null,
}

const ticketsPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEES_TICKETS_PAYMENT_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list
            }
        case EMPLOYEES_TICKETS_PAYMENT_EXPORT_ACTION:
            return {
                ...state,
                file: action.doc
            }
        case EMPLOYEES_TICKETS_PAYMENT_UNSET_EXPORT_ACTION: {
            return {
                ...state,
                file: null
            }
        }
        default: return state
    }
}

export default ticketsPaymentReducer