import {
    PAYMENTS_HISTORY_LIST_ACTION,
    PAYMENTS_HISTORY_DELETE_ACTION,
    PAYMENTS_HISTORY_SAVE_ACTION
} from '../../constants/actions'

const initialState = {
    list: [],
    reload: false,
    return: false
}

const paymentReducer = (
    state = initialState,
    action
) => {

    switch (action.type) {

        case PAYMENTS_HISTORY_LIST_ACTION:
            return {
                ...state,
                reload: false,
                list: action.list
            } 
        case PAYMENTS_HISTORY_DELETE_ACTION:
            return {
                ...state,
                reload: true
            }
        case PAYMENTS_HISTORY_SAVE_ACTION:
            return {
                ...state,
                reload: true
            }
        default:
            return state
    }
}

export default paymentReducer