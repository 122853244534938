import { SUBSCRIPTION_TAB_CHANGE_ACTION } from '../../constants/actions'

const initialState = {
    currentTab: null
}

const tabsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SUBSCRIPTION_TAB_CHANGE_ACTION:
            return {
                currentTab: action.tab
            }
        default:
            return state    
    }

}

export default tabsReducer