// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import customer from './customer'
import upload from './upload'
import form from './form'
import sms from './sms'
import jobSite from './job-site'
import google from './google'
import employee from './employee'
import subscription from './subscription'
import company from './company'
import schedule from './schedule'
import payment from './payment'
import shift from './shift'
import rating from "./employee/rating"
import daysoff from './daysoff'
import availability from './availability'
import ticket from './ticket'
import contactRequest from './contact'
import shiftTemplate from "./shiftTemplates"
import scheduler from './scheduler'
import chat from './chat'
import dropShift from './dropShiftRequest'
import statistics from "./statistics"
import adminStatistics from "./admin-statistics"
import insurance from "./insurance"
import dashboard from "./dashboard"
import activity from './employee-activity'
import adminBalance from './admin-balance'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  customer,
  upload,
  form,
  sms,
  jobSite,
  google,
  employee,
  subscription,
  company,
  payment,
  shift,
  rating,
  schedule,
  daysoff,
  availability,
  ticket,
  contactRequest,
  shiftTemplate,
  scheduler,
  chat,
  dropShift,
  statistics,
  adminStatistics,
  insurance,
  dashboard,
  activity,
  adminBalance
})

export default rootReducer
