import {
    SUBSCRIPTION_CREATE_CAR_TAGS_ACTION,
    SUBSCRIPTION_GET_CAR_TAGS_ACTION,
    SUBSCRIPTION_GET_CURRENT_CAR_TAGS_ACTION
} from "../../constants/actions"

const initialState = {
    list: [],
    current: {},
    payment: {},
    reload: false
}

const carTagsReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_GET_CAR_TAGS_ACTION:
            return {
                ...state,
                list: action.list
            }

        case SUBSCRIPTION_GET_CURRENT_CAR_TAGS_ACTION:
            return {
                ...state,
                current: action.list,
                reload: false
            }

        case SUBSCRIPTION_CREATE_CAR_TAGS_ACTION:
            return {
                ...state,
                payment: action.payment,
                reload: true
            }

        default:
            return state

    }
}

export default carTagsReducer