import {
    COMPANY_DELETE_ACTION,
    COMPANY_GET_ACCOUNT_HOLDER_ACTION,
    COMPANY_GET_ACTION,
    COMPANY_GET_LIST_ACTION,
    COMPANY_GET_RATES_ACTION, COMPANY_GET_RATING_ACTION,
    COMPANY_SAVE_ACTION,
    COMPANY_SHOW_ACTION,
    COMPANY_UPDATE_ACCOUNT_HOLDER_ACTION, COMPANY_UPDATE_PARKING_TERMS
} from '../../constants/actions'

const initialState = {
    list: {},
    current: {},
    reload: false,
    return: false,
    rates: [],
    reloadCurrent: false,
    holder: {},
    rating: []
}

/**
 * Company reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */

const companyReducer = (state = initialState, action) => {

    switch (action.type) {

        case COMPANY_GET_LIST_ACTION:

            return {
                ...state,
                list: action.list,
                current: {},
                reload: false,
                return: false
            }

        case COMPANY_SHOW_ACTION:

            return {
                ...state,
                current: action.company
            }

        case COMPANY_SAVE_ACTION:
        
            return {
                ...state,
                return: true,
                current: action.company,
                reloadCurrent: true
            }
        
        case COMPANY_DELETE_ACTION:

            return {
                ...state,
                reload: true,
                current: {}
            }

        case COMPANY_GET_ACTION:
            return {
                ...state,
                current: action.current,
                reloadCurrent: false
            }

        case COMPANY_GET_RATES_ACTION:
            return {
                ...state,
                rates: action.list
            }

        case COMPANY_GET_ACCOUNT_HOLDER_ACTION:
            return {
                ...state,
                holder: action.holder,
                reloadCurrent: false
            }

        case COMPANY_UPDATE_ACCOUNT_HOLDER_ACTION:
            return {
                ...state,
                reloadCurrent: true
            }

        case COMPANY_GET_RATING_ACTION:
            return {
                ...state,
                rating: action.rates
            }

        case COMPANY_UPDATE_PARKING_TERMS:
            return {
                ...state,
                reloadCurrent: true
            }

        default:
            
            return state
    }
}

export default companyReducer