import JwtService from './jwtService'

/**
 * Auth service for customers and employees
 *
 * @returns {object}
 */
const jwt = new JwtService({
    loginEndpoint: '/auth/access',
    registerEndpoint: '/auth/sign-in',
    requestPasswordEndpoint: '/auth/forgot-password',
    setPasswordEndpoint: '/auth/reset-password',
    logoutEndpoint: '/auth/access',
    storageUserDataKeyName: 'userData',
    storageSubscriptionKeyData: 'subscriptionData',
    storageBalanceKeyData: 'balanceData',
    storagePayoutData: 'payoutData',
    storageStripeUrl: 'stripeUrl'
})

export default jwt
