import {GET_ADMIN_BALANCE_SERVICE} from "../../constants/actions"

const initialState = {
    balance: null
}

const balanceReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_ADMIN_BALANCE_SERVICE:
            return {
                balance: action.payload
            }

        default: return state
    }
}

export default balanceReducer