import {
    EARNING_HISTORY_EXPORT_ACTION,
    EARNING_HISTORY_GET_LIST_ACTION, EARNING_HISTORY_GET_TOTALS_ACTION,
    EARNING_HISTORY_UNSET_EXPORT_ACTION
} from "../../constants/actions"

const initialState = {
    list: [],
    file: null,
    reload: false,
    totals: {}
}

const earningHistoryReducer = (state = initialState, action) => {

    switch (action.type) {

        case EARNING_HISTORY_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list
            }

        case EARNING_HISTORY_EXPORT_ACTION: {
            return {
                ...state,
                file: action.doc
            }
        }

        case EARNING_HISTORY_UNSET_EXPORT_ACTION: {
            return {
                ...state,
                file: null
            }
        }

        case EARNING_HISTORY_GET_TOTALS_ACTION: {
            return {
                ...state,
                totals: action.totals
            }
        }

        default:
            return state

    }
}

export default earningHistoryReducer