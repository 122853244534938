import {
    TICKET_DELETE_ACTION, TICKET_GET_ALL_LOGS,
    TICKET_GET_LIST_ACTION, TICKET_GET_LOGS,
    TICKET_SAVE_ACTION,
    TICKET_SHOW_ACTION,
    TICKET_GET_NOTES,
    TICKET_NOTES_HANDLE_CONFIRMATION, TICKET_REFUND_ACTION
} from "../../constants/actions"

const initialState = {
    list: {
        page: 1,
        data: [],
        total: 0
    },
    current: {},
    reload: false,
    media: [],
    logs: [],
    allLogs: [],
    notes: {
        current_page: 1,
        data: [],
        total: 0
    },
    confirmModalId: null,
    return: false
}

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKET_GET_LIST_ACTION:
            return {
                ...state,
                return: false,
                list: action.list,
                current: {},
                reload: false
            }
        case TICKET_SHOW_ACTION:
            return {
                ...state,
                return: false,
                media: action.ticket.media,
                current: action.ticket
            }
        case TICKET_SAVE_ACTION:
            return {
                ...state,
                return: true,
                current: {},
                reload: true
            }
        case TICKET_DELETE_ACTION:
            return {
                ...state,
                confirmModalId: null,
                current: {},
                reload: true
            }
        case TICKET_REFUND_ACTION:
            return {
                ...state,
                return: true,
                reload: true,
                current: {}
            }
        case TICKET_GET_LOGS:
            return {
                ...state,
                logs: action.logs
            }
        case TICKET_GET_NOTES:
            
            return {
                ...state,
                confirmModalId: null,
                notes: action.notes
            }
        case TICKET_GET_ALL_LOGS:
            return {
                ...state,
                allLogs: action.allLogs
            }
        case TICKET_NOTES_HANDLE_CONFIRMATION:
            return {
                ...state,
                confirmModalId: action.id
            }
        default:
            return state
    }
}

export default ticketReducer
