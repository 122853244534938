import {
    DROP_SHIFT_REQUEST_DECLINE_ALL_ACTION,
    DROP_SHIFT_REQUEST_RELOAD_ACTION,
    DROP_SHIFT_REQUEST_UPDATE_ACTION
} from "../../constants/actions"

const initialState = {
    reload: false
}

const dropShiftReducer = (state = initialState, action) => {
    switch (action.type) {
        case DROP_SHIFT_REQUEST_UPDATE_ACTION:
            return {
                ...state,
                reload: true
            }
        case DROP_SHIFT_REQUEST_RELOAD_ACTION:
            return {
                ...state,
                reload: false
            }
        case DROP_SHIFT_REQUEST_DECLINE_ALL_ACTION:
            return {
                ...state,
                reload: true
            }
        default:
            return state
    }
}

export default dropShiftReducer