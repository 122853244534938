import {AVAILABILITY_GET_LIST_ACTION, AVAILABILITY_SAVE_ACTION} from "../../constants/actions"

const initialState = {
    list: [],
    reload: false
}

const availabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case AVAILABILITY_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list,
                reload: false
            }
        case AVAILABILITY_SAVE_ACTION:
            return {
                ...state,
                reload: true
            }
        default: return state
    }
}

export default availabilityReducer
