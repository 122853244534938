import { SCHEDULE_GET_LIST } from '../../constants/actions'

const initialState = {
    list: [],
    reload: true
}

const scheduleReducer = (
    state = initialState,
    action
) => {

    switch (action.type) {

        case SCHEDULE_GET_LIST: 
            return {
                ...state,
                list: action.list,
                reload: false
            }
        default:
            return state

    }
}

export default scheduleReducer
