import { Ability } from '@casl/ability'
import { adminAbility, accountHolderAbility  } from './roleAbility'
import { ROLE_ADMIN } from './roles'
import useJwt from '../../services/auth/jwt/useJwt'

const userData = useJwt.getUserData()

const userAbility = userData ? (userData.role === ROLE_ADMIN ? adminAbility : accountHolderAbility) : null

export default new Ability(userAbility || [
    {
        action: 'read',
        subject: 'Auth'
    }
])
