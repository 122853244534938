import {
    DAYS_OFF_CREATE_ACTION,
    DAYS_OFF_DELETE_ACTION,
    DAYS_OFF_GET_LIST,
    DAYS_OFF_SHOW_ACTION,
    DAYS_OFF_UPDATE_ACTION
} from "../../constants/actions"

const initialState = {
    list: [],
    current: {},
    reload: false
}

/**
 * Employee days off reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */

const daysOffReducer = (state = initialState, action) => {
    switch (action.type) {
        case DAYS_OFF_GET_LIST:
            return {
                ...state,
                list: action.list,
                reload: false
            }
        case DAYS_OFF_SHOW_ACTION:
            return {
                ...state,
                current: action.current
            }
        case DAYS_OFF_UPDATE_ACTION:
            return {
                ...state,
                reload: true
            }
        case DAYS_OFF_DELETE_ACTION:
            return {
                ...state,
                reload: true
            }
        case DAYS_OFF_CREATE_ACTION:
            return {
                ...state,
                reload: true
            }
        default:
            return state
    }
}

export default daysOffReducer

