import {
    UPLOAD_IMAGE_ACTION,
    RESET_IMAGE_ACTION,
    UPLOAD_INSURANCE_ACTION,
    RESET_INSURANCE_ACTION,
    QR_CODE_LOADING_START, UPLOAD_QR_ACTION, RESET_QR_ACTION
} from '../../constants/actions'
import {QRCashApp, QRVenmo, QRZelle} from "../../../components/QRUpload/QRUploadTypes"

const initialState = {
    imageInfo: {},
    insuranceInfo: {},
    cashAppQr: {},
    venmoQr: {},
    zelleQr: {},
    cashAppQrLoading: false,
    venmoQrLoading: false,
    zelleQrLoading: false
}

const uploadReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPLOAD_IMAGE_ACTION:

            return {
                ...state,
                imageInfo: action.imageInfo
            }

        case RESET_IMAGE_ACTION:

            return {
                ...state,
                imageInfo: {}
            }

        case UPLOAD_INSURANCE_ACTION:

            return {
                ...state,
                insuranceInfo: action.insuranceInfo
            }

        case RESET_INSURANCE_ACTION:

            return {
                ...state,
                insuranceInfo: {}
            }

        case UPLOAD_QR_ACTION:

            switch (action.QRType) {
                case QRCashApp:
                    return {
                        ...state,
                        cashAppQr: action.qrImage,
                        cashAppQrLoading: false
                    }
                case QRVenmo:
                    return {
                        ...state,
                        venmoQr: action.qrImage,
                        venmoQrLoading: false
                    }

                case QRZelle :
                    return {
                        ...state,
                        zelleQr: action.qrImage,
                        zelleQrLoading: false
                    }
                default: break
            }

        case RESET_QR_ACTION:

            switch (action.QRType) {
                case QRCashApp:
                    return {
                        ...state,
                        cashAppQr: {}
                    }
                case QRVenmo:
                    return {
                        ...state,
                        venmoQr: {}
                    }

                case QRZelle :
                    return {
                        ...state,
                        zelleQr: {}
                    }
                default: break
            }


        case QR_CODE_LOADING_START:

            switch (action.QRType) {
                case QRCashApp:
                    return {
                        ...state,
                        cashAppQrLoading: true
                    }
                case QRVenmo:
                    return {
                        ...state,
                        venmoQrLoading: true
                    }

                case QRZelle :
                    return {
                        ...state,
                        zelleQrLoading: true
                    }
                default: break
            }


        default:

            return state
    }
}

export default uploadReducer
