import {EMPLOYEE_PAYMENTS_GET_RATING_ACTION} from "../../constants/actions"

const initialState = {
    list: {
        page: 1,
        data: [],
        total: 0
    },
    reload: false
}

/**
 * Rating reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */

const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEE_PAYMENTS_GET_RATING_ACTION:
            return {
                ...state,
                list: action.list
            }
        default:
            return state
    }
}

export default ratingReducer
