import { FORM_SERVER_ERROR_ACTION, FORM_RESET_SERVER_ERROR_ACTION } from '../../constants/actions'

const initialState = {
    errors: {}
}

/**
 * Form reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */
const formReducer = (state = initialState, action) => {

    switch (action.type) {

        case FORM_SERVER_ERROR_ACTION:

            return {
                ...state,
                errors: action.errors
            }

        case FORM_RESET_SERVER_ERROR_ACTION:

            return {
                ...state,
                errors: {}
            }

        default:

            return state
    }
}

export default formReducer
