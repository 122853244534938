import {SUBSCRIPTION_GET_LIMIT_EMPLOYEE_ACTION} from "../../constants/actions"

const initialState = {
    limit: {}
}

const limitReducer = (state = initialState, action) => {

    switch (action.type) {

        case SUBSCRIPTION_GET_LIMIT_EMPLOYEE_ACTION:
            return {
                ...state,
                limit: action.limit
            }

        default: return state
    }
}

export default limitReducer

