import {TRANSACTION_GET_LIST_ACTION, TRANSACTION_UPDATE_PAYMENT_ACTION} from "../../constants/actions"

const initialState = {
    list: [],
    reload: false
}

const transactionReducer = (state = initialState, action) => {

    switch (action.type) {

        case TRANSACTION_GET_LIST_ACTION:
            return {
                ...state,
                list: action.list,
                reload: false
            }

        case TRANSACTION_UPDATE_PAYMENT_ACTION:
            return {
                ...state,
                reload: true
            }

        default:
            return state

    }
}

export default transactionReducer