import {
    JOB_SITE_GET_LIST_ACTION,
    JOB_SITE_SHOW_ACTION,
    JOB_SITE_SAVE_ACTION,
    JOB_SITE_DELETE_ACTION, JOB_SITE_DELETE_ERROR, JOB_SITE_CLEAR_ERROR,
    JOB_SITE_GET_HIDDEN_LIST_ACTION
} from '../../constants/actions'

const initialState = {
    list: {
        page: 1,
        data: [],
        total: 0
    },
    hiddenList: {
        page: 1,
        data: [],
        total: 0
    },
    current: {},
    reload: false,
    return: false,
    error: false
}

/**
 * Job site reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @returns {object}
 */
const jobSiteReducer = (state = initialState, action) => {

    switch (action.type) {

        case JOB_SITE_GET_LIST_ACTION:

            return {
                ...state,
                list: action.list,
                current: {},
                reload: false,
                return: false
            }

        case JOB_SITE_GET_HIDDEN_LIST_ACTION:

            return {
                ...state,
                hiddenList: action.hiddenList,
                current: {},
                reload: false,
                return: false
            }
            
        case JOB_SITE_SHOW_ACTION:

            return {
                ...state,
                current: action.jobSite
            }

        case JOB_SITE_SAVE_ACTION:

            return {
                ...state,
                return: true,
                current: {}
            }

        case JOB_SITE_DELETE_ACTION:

            return {
                ...state,
                reload: true,
                current: {}
            }

        case JOB_SITE_DELETE_ERROR:
            return {
                ...state,
                error: true
            }

        case JOB_SITE_CLEAR_ERROR:
            return {
                ...state,
                error: false
            }

        default:

            return state
    }
}

export default jobSiteReducer
